import { routes } from 'appConstants/routes'
import { Home, NftStaking } from 'pages'
import { ComingSoon } from 'pages/ComingSoon'
import { NotFound } from 'pages/NotFound'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

export const RouteManager: FC = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path={routes.root} element={<Home />} />
      {/* <Route path={routes.marketplace.root} element={<ComingSoon title={routes.marketplace.title} />} /> */}
      <Route
        path={routes.dex.root}
        element={<ComingSoon title={routes.dex.title} />}
      />
      {/* <Route
        path={routes.farming.root}
        element={<Farming title={routes.farming.title} />}
      /> */}
      <Route
        path={routes.nftStaking.root}
        element={<NftStaking title={routes.nftStaking.title} />}
      />
      {/* <Route path={routes.lottery.root} element={<Lottery title={routes.lottery.title} />} /> */}
      {/* <Route path={routes.crowdsale.root} element={<ComingSoon title={routes.crowdsale.title} />} /> */}
    </Routes>
  )
}
