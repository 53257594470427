export const routes = {
  root: '/',
  title: 'Home',
  marketplace: {
    root: '/marketplace',
    title: 'Marketplace',
  },
  //   farming: {
  //     root: '/farming',
  //     title: 'Yield Farming',
  //   },
  nftStaking: {
    root: '/nft_staking',
    title: 'NFT Staking',
  },
  // lottery: {
  //   root: '/lottery',
  //   title: 'Community Draw',
  // },
  dex: {
    root: '/dex',
    title: 'DEX',
  },
  // crowdsale: {
  //   root: '/crowdsale',
  //   title: 'Crowdsale',
  // },
}
