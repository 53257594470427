import { VFC } from 'react';

import cn from 'clsx';
import { H1 } from 'components/Typography';
// import { Button } from 'components/Button';

// import { HomePageAnchors } from 'containers/Header/Header.helpers';
// import { Link } from 'react-router-dom';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import styles from './styles.module.scss';
import './styles.scss';

import Marketplace from '../../assets/img/banner/marketplace.png';
import Staking from '../../assets/img/banner/staking.png';

export interface BannerProps {
  className?: string;
}

export const Banner: VFC<BannerProps> = ({ className }) => {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    autoplayTimeout: 15000,
    autoplayHoverPause: true,
    center: true,
  };

  return (
    <div className={cn(styles.banner, className)}>
      <OwlCarousel className="owl-theme" {...options}>
        <div className="item">
          <a href="https://marketplace.artcpaclub.com" target="_blank" rel="noreferrer">
            <img src={Staking} alt="" />
          </a>
        </div>
        <div className="item">
          <img src={Marketplace} alt="" />
        </div>
        <div className="item">
          <div className={styles.container}>
            <H1 align="center" className={styles.bannerText}>
              The place where Community, Art, AI,
              and Blockchain blend together to unlock
              and enhance human potential.
            </H1>
            {/* <div style={{ textAlign: 'center', marginTop: 30 }}>
              <Link to={HomePageAnchors.BUY} className={styles.navLink}>
                <Button variant="filled" size="md"> BUY CPA </Button>
              </Link>
            </div> */}
          </div>
        </div>
      </OwlCarousel>
    </div>
  );
};
