import { AbiRegistry } from '@multiversx/sdk-core/out';
import { TSingleToken } from 'types';

import {
  CrowdSaleABI,
  TokenStakingABI,
  NftStakingABI,
  LotteryABI,
  LpFarmingABI,
} from './abi';

export const isDev = false;

export const MainToken: TSingleToken = {
  symbol: 'CPA',
  address: isDev ? 'CPA-eabe32' : 'CPA-97530a',
  decimals: '6',
  price: '',
};

export const FounderToken = {
  symbol: 'CPA',
  id: isDev ? 'CPA-60c1a9' : 'CPA-76d979',
  amount: '1',
};

export const EarthSpiritToken = {
  symbol: 'EarthSpirit',
  id: isDev ? 'EST-926799' : 'CPA-8f71d0',
  amount: '1',
};

export const CommunityToken = {
  symbol: 'Community',
  id: isDev ? 'CCT-3883fe' : 'CPA-c6d2fb',
  amount: '1',
};

export const LpToken: TSingleToken = {
  symbol: 'CPAUSDC',
  address: isDev ? 'CPAUSDC-d25615' : 'CPAUSDC-c4669b',
  decimals: '18',
  price: '',
};

export enum EContracts{
  crowdSale = 'crowdSale',
  tokenStaking = 'tokenStaking',
  nftStaking = 'nftStaking',
  lottery = 'lottery',
  lpFarming = 'farming'
}

type TContracts = {
  [key in EContracts]: {
    address: string;
    abi: AbiRegistry
  }
};

export const contracts:TContracts = {
  [EContracts.crowdSale]: {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgq4rm6pasm2y749p45zkf8ydl7vjxe7wrlp0wqzxuaw3' : 'erd1qqqqqqqqqqqqqpgq93sjwk5u68xs08ju2gz4qnsefn2jmjg3vszqdlwyl2',
    abi: CrowdSaleABI,
  },
  [EContracts.tokenStaking]: {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgqakq3jcekrnwa7xxz85h7k6vgu2dm9vwggn8q6ft39a' : 'erd1qqqqqqqqqqqqqpgq73van5qa6flg4emchzk89s0amyd8yv6gfy8q8w428k',
    abi: TokenStakingABI,
  },
  [EContracts.nftStaking]: {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgqaekwv8sfmpery3mhv33k0gv3kf6knu42gn8qnkc9hm' : 'erd1qqqqqqqqqqqqqpgq6a0wgz4akts00kgpwcg49069wng9y8cufy8q7dnaxj',
    abi: NftStakingABI,
  },
  [EContracts.lottery]: {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgqm9y6snc5lej3rfndg0g0shpa06u50v69kqys5vcfrj' : 'erd1qqqqqqqqqqqqqpgqqyfl9zj49xdu52a9cj33mh4de5md7lxlkqyspjh69v',
    abi: LotteryABI,
  },
  [EContracts.lpFarming]: {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgqg4hglmq2yp08svs3qlp3qqs2gngw0qzakqysr7za2e' : 'erd1qqqqqqqqqqqqqpgqj2g8x8vq9luepya896f8uz6sh6m4904akqyspuzmhz',
    abi: LpFarmingABI,
  },
};

export const getContract = (contract: EContracts) => {
  return contracts[contract];
};
