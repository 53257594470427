import React from 'react';

import { BaseSVGIcon } from 'assets/icons/icons/components/BaseSVGIcon';
import { IconProps } from 'assets/icons/icons/icons.types';

export const HomeIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon
    width="15"
    height="15"
    fill="none"
    viewBox="0 0 15 15"
    {...props}
  >
    <path d="M2.93408 8.37278V7.56636L2.36386 8.13659L2.10025 8.40019C1.87923 8.62122 1.52087 8.62122 1.29985 8.40019L1.06365 8.63638L1.29985 8.40019C1.07882 8.17916 1.07882 7.82081 1.29985 7.59979L1.06435 7.36429L1.29985 7.59978L7.59985 1.29978L7.36435 1.06429L7.59985 1.29978C7.82087 1.07876 8.17923 1.07876 8.40025 1.29978L8.63644 1.06359L8.40025 1.29978L14.7003 7.59978C14.9213 7.82081 14.9213 8.17916 14.7003 8.40019C14.4792 8.62122 14.1209 8.62122 13.8998 8.40019L13.6362 8.13659L13.066 7.56637V8.37278V14.3C13.066 14.6126 12.8126 14.866 12.5 14.866H10.7C10.3875 14.866 10.1341 14.6126 10.1341 14.3V12.5C10.1341 11.8185 9.58158 11.266 8.90005 11.266H7.10005C6.41851 11.266 5.86602 11.8185 5.86602 12.5V14.3C5.86602 14.6126 5.61263 14.866 5.30005 14.866H3.50005C3.18747 14.866 2.93408 14.6126 2.93408 14.3V8.37278Z" fill="white" stroke="white" strokeWidth="0.668056" />
  </BaseSVGIcon>
);
