import { VFC } from 'react';

import cn from 'clsx';
import { H3, Text } from 'components/Typography';
import { LinkedInIcon, TwitterIcon } from 'assets/icons/icons';
import styles from './styles.module.scss';

const iconsMap = {
  linkedIn: <LinkedInIcon />,
  twitter: <TwitterIcon />,
};
export interface TeamCardProps {
  className?: string;
  image?: string;
  personName?: string;
  personPosition?: string;
  socials?: { [key: string]: string }
}
export const TeamCard: VFC<TeamCardProps> = ({ className, image, personName, personPosition, socials,
}) => {
  return (
    <div className={cn(className, styles.root)}>
      <img src={image} alt="Person" className={styles.avatar} />
      <H3 weight="semiBold">{personName}</H3>
      <Text size="m">{personPosition}</Text>
      <div className={styles.socials}>
        {Object.entries(socials).map(([social, link]) => (link.length ?
          <a className={cn(styles.social, styles.active)} target="_blank" href={link} rel="noreferrer">{iconsMap[social]}</a> :
          <span className={styles.social}>{iconsMap[social]}</span>))}
      </div>
    </div>
  );
};
