import { foundersCollectionSrc, earthSpiritsCollectionSrc, communityCollectionSrc, womenDaySpecialCollectionSrc, seaCreationCollectionSrc, guardiansOfTheRealmSrc, cpaGoldenSrc, cpaCouncilorSrc } from 'assets/img/icons';
// @ts-ignore
import goddessSrc from '../../assets/img/icons/Angelica.mp4';

export const NftCollectionsHelper = [
  { image: foundersCollectionSrc, title: 'ArtCPAclubFounders', href: 'https://www.frameit.gg/marketplace/CPA-76d979/mint?sp=true' },
  { image: earthSpiritsCollectionSrc, title: 'EarthSpirits', href: 'https://inspire.art/collections/CPA-8f71d0' },
  { image: communityCollectionSrc, title: 'Community', href: 'https://inspire.art/collections/CPA-c6d2fb' },
  { image: womenDaySpecialCollectionSrc, title: 'WomenDaySpecial', href: 'https://inspire.art/collections/CPA-8a6457' },
  { image: seaCreationCollectionSrc, title: 'Sea Creation', href: 'https://opensea.io/collection/sea-creation' },
  { image: guardiansOfTheRealmSrc, title: 'GuardiansOfTheRealm', href: 'https://inspire.art/collections/CPA-aad579' },
  { image: cpaGoldenSrc, title: 'CPA Golden', href: 'https://xoxno.com/buy/ArtCPclub/CPA' },
  { image: cpaCouncilorSrc, title: 'CPA Councilor', href: 'https://xspotlight.com/collections/CPA-534a5f' },
  { image: goddessSrc, title: 'CPA Goddess', href: 'https://xspotlight.com/collections/CPA-f1fc6c', isVideo: true },
];
