import { VFC } from 'react'
import cn from 'clsx'
import { H3, Button } from 'components'
import { NftCard } from './components/NftCard'
import styles from './styles.module.scss'

export interface NftStakingPoolProps {
  className?: string
  loggedIn?: string
  reward?: number
  stakedNfts?: any[]
  ownedNfts?: any[]
  stake?: (collection, nonce) => void
  unstake?: (collection, nonce) => void
  unstakeAllOrClaim?: (method) => void
}

export const NftStakingPool: VFC<NftStakingPoolProps> = ({
  className,
  stakedNfts,
  unstake,
  unstakeAllOrClaim,
  loggedIn,
  reward,
}) => {
  return (
    <>
      <div className={styles.buttonGroup}>
        <H3 className={styles.nftInfo}>
          You can stake{' '}
          <a
            href="https://xspotlight.com/collections/CPA-8f71d0"
            rel="noreferrer"
            target="_blank"
            className={styles.highlight}
          >
            EarthSpirits
          </a>{' '}
          and{' '}
          <a
            href="https://xspotlight.com/collections/CPA-c6d2fb"
            rel="noreferrer"
            target="_blank"
            className={styles.highlight}
          >
            Community
          </a>{' '}
          NFTs
        </H3>
        <Button
          disabled={!loggedIn || !stakedNfts.length}
          className={styles.button}
          onClick={() => unstakeAllOrClaim('unstakeAll')}
        >
          UNSTAKE ALL
        </Button>
        <Button
          disabled={!loggedIn || !reward}
          className={styles.button}
          onClick={() => unstakeAllOrClaim('claimReward')}
        >
          CLAIM REWARD
        </Button>
      </div>

      <div className={cn(className, styles.wrapperCard)}>
        {stakedNfts.map((nftCard) => (
          <div className={styles.nft} key={nftCard.identifier}>
            <NftCard
              {...nftCard}
              staked
              onAction={() => unstake(nftCard.collection, nftCard.nonce)}
            />
          </div>
        ))}
      </div>

      <div className={styles.infoGroup} style={{ paddingLeft: 32 }}>
        <H3 color="error">
          This pool has been closed, please migrate to the new pools on{' '}
          <a href="https://marketplace.artcpaclub.com/staking/nft">
            <u>our Marketplace</u>
          </a>
          .
        </H3>
      </div>
    </>
  )
}
