import { VFC } from 'react';

import cn from 'clsx';
import { H2, Button } from 'components';
import { Card } from 'components/Card';
import styles from './styles.module.scss';

export interface NftCardProps {
  className?: string;
  image: string;
  title: string;
  href: string;
  isVideo?: boolean;
}

export const NftCard: VFC<NftCardProps> = ({ className, image, title, href, isVideo = false }) => {
  return (
    <Card className={cn(className, styles.nftCard)}>
      {
        isVideo ? (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video loop autoPlay muted className={styles.imgCard}>
            <source src={image} type="video/mp4" />
          </video>
        ) : (
          <img src={image} alt={title} className={styles.imgCard} />
        )
      }
      <H2 align="center" className={styles.title} weight="semiBold">{title}</H2>
      <Button href={href} size="md" variant="filled" className={styles.viewButton}>VIEW</Button>
    </Card>

  );
};
