import { FirstCard, FourthCard, FifthCard, SixthCard } from 'assets/img/icons'

export const teamData = [
  {
    image: FirstCard,
    personName: 'Ciprian Popovici',
    personPosition: 'Founder & CEO',
    socials: {
      linkedIn: 'https://www.linkedin.com/in/popovici-ciprian-74181778/',
    },
  },
  {
    image: FourthCard,
    personName: 'Luiza Zaharia',
    personPosition: 'Art Director',
    socials: {
      linkedIn: '',
    },
  },
  {
    image: FifthCard,
    personName: 'Orojan Peter',
    personPosition: 'Artist',
    socials: {
      twitter: 'https://twitter.com/PeterOrojan',
    },
  },
  {
    image: SixthCard,
    personName: 'Vizoli Petru Vasile',
    personPosition: 'Social Media Admin',
    socials: {
      twitter: 'https://twitter.com/VizoliP',
    },
  },
]
