import { AboutUs } from 'components/AboutUs'
import { Banner } from 'components/Banner'
// import { MainVideo } from 'components/MainVideo';
import { NftCollections } from 'components/NftCollections'
// import { Crowdsale } from 'components/Crowdsale';
import { Team } from 'components/Team'
import { FC } from 'react'

import s from './Home.module.scss'

const Home: FC = () => {
  return (
    <div className={s.homeWrapper}>
      <Banner />
      {/* <Crowdsale /> */}
      <AboutUs />
      {/* <MainVideo /> */}
      <NftCollections />
      <Team />
    </div>
  )
}
export default Home
