import { Button, Logo } from 'components';
import { VFC, useEffect, useState, useCallback } from 'react';
import { useWindowState } from 'hooks';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import {
  logout,
} from '@multiversx/sdk-dapp/utils';
import { useDispatch } from 'react-redux';
import { updateUserState } from 'store/user/reducer';
import { WrapContainer } from 'components/WrapContainer';
import { LogoIcon } from 'assets/img/icons';
import { useElrondApi } from 'containers/ElrondAPI';
import { Link, useSearchParams } from 'react-router-dom';
import { homePageNavigation } from './Header.helpers';

import styles from './styles.module.scss';
import { MenuButton } from './components/MenuButton';
import { ConnectButton } from './components/ConnectButton';

export interface HeaderProps {
  className?: string;
}

export const Header: VFC<HeaderProps> = () => {
  const { width } = useWindowState();
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const referrer = searchParams.get('ref')?.replaceAll('/', '');

  const { address: elraddress } = useGetAccountInfo();
  const { getAccountsTokens } = useElrondApi();

  const [isTablet, setIsTablet] = useState(true);
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if(width) {
      setIsTablet(width < 1200);
      setIsMobile(width < 768);
    }
  }, [width]);

  // const isTablet = useMemo(() => {
  //   if(width) {
  //     return width < 1200;
  //   }

  //   return false;
  // }, [width]);

  // const isMobile = useMemo(() => {
  //   if(width) {
  //     return width < 768;
  //   }

  //   return true;
  // }, [width]);

  const [isConnectModalOpen, setConnectModalOpen] = useState(false);

  const handleDisconnect = () => {
    dispatch(updateUserState({
      address: '',
    }));
    logout();
  };

  const closeModal = useCallback(() => setConnectModalOpen(false), []);
  const openModal = useCallback(() => setConnectModalOpen(true), []);

  const getUserData = useCallback(async (address: string) => {
    const userTokens = await getAccountsTokens();
    dispatch(updateUserState({
      address,
      tokens: userTokens,
    }));
  }, [dispatch, getAccountsTokens]);

  useEffect(() => {
    if (elraddress) {
      getUserData(elraddress);
    }
    closeModal();
  }, [closeModal, dispatch, elraddress, getUserData]);

  return (
    <WrapContainer className={styles.header}>
      {isMobile ? (
        <>
          <MenuButton isMobile={isTablet} />
          <img src={LogoIcon} alt="coin" className={styles.coin} />
          <ConnectButton
            isMobile
            onCloseModal={closeModal}
            onOpenModal={openModal}
            onDisconnect={handleDisconnect}
            address={elraddress}
            isOpen={isConnectModalOpen}
          />
        </>
      ) : (
        <>
          <Link to={`/${referrer ? `?ref=${referrer}` : ''}`}>
            <Logo />
          </Link>
          {!isTablet && (
          <>
            {homePageNavigation.map(({ label, anchorId, isOuterLink, link }) => {
              if (isOuterLink) {
                return (
                  <Button key={label} className={styles.headerNav} href={link} variant="text">{label}</Button>
                );
              }
              return (
                <Link key={label} to={anchorId} className={styles.navLink}>
                  <Button className={styles.headerNav} variant="text">{label}</Button>
                </Link>
              );
            })}
          </>
          )}
          {/* <Link to={`${referrer ? `/?ref=${referrer}` : ''}${HomePageAnchors.BUY}`}>
            <Button className={styles.buyButton}>Buy CPA</Button>
          </Link> */}
          <ConnectButton
            isMobile={false}
            onCloseModal={closeModal}
            onOpenModal={openModal}
            onDisconnect={handleDisconnect}
            address={elraddress}
            isOpen={isConnectModalOpen}
          />
          <MenuButton isMobile={isTablet} />
        </>
      )}
    </WrapContainer>
  );
};
