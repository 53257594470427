import React from 'react';

import { BaseSVGIcon } from 'assets/icons/icons/components/BaseSVGIcon';
import { IconProps } from 'assets/icons/icons/icons.types';

export const Twitter: React.FC<IconProps> = (props) => (
  <BaseSVGIcon
    width="50"
    height="50"
    fill="none"
    viewBox="0 0 50 50"
    {...props}
  >
    <circle cx="25" cy="25" r="20" fill="#ffffff" />
    <path d="M24.3731 0.245605C11.1882 0.245605 0.497437 10.9699 0.497437 24.1961C0.497437 37.4224 11.1882 48.1467 24.3731 48.1467C37.558 48.1467 48.2487 37.4224 48.2487 24.1961C48.2487 10.9699 37.558 0.245605 24.3731 0.245605ZM35.8472 18.2994C35.8632 18.5507 35.8632 18.8126 35.8632 19.0692C35.8632 26.9173 29.905 35.9576 19.0171 35.9576C15.6595 35.9576 12.5472 34.9792 9.92512 33.2952C10.4048 33.3487 10.8631 33.3701 11.3534 33.3701C14.1247 33.3701 16.6721 32.4291 18.7026 30.836C16.1019 30.7825 13.9168 29.0718 13.1707 26.7195C14.082 26.8532 14.9028 26.8532 15.8407 26.6126C14.5016 26.3397 13.298 25.6101 12.4343 24.5478C11.5706 23.4856 11.1002 22.1561 11.1029 20.7853V20.7105C11.8863 21.1542 12.8083 21.4269 13.7729 21.4643C12.962 20.9222 12.297 20.1877 11.8368 19.326C11.3767 18.4643 11.1355 17.502 11.1349 16.5245C11.1349 15.4178 11.4227 14.4074 11.9396 13.5307C13.426 15.3662 15.2808 16.8674 17.3834 17.9367C19.486 19.0061 21.7894 19.6196 24.1439 19.7375C23.3072 15.7012 26.313 12.4347 29.9263 12.4347C31.6317 12.4347 33.1666 13.1511 34.2484 14.3059C35.5861 14.0546 36.8652 13.5521 38.0056 12.8784C37.5633 14.2524 36.636 15.4125 35.4049 16.1449C36.5987 16.0166 37.7498 15.6852 38.8157 15.22C38.011 16.4069 37.0037 17.4601 35.8472 18.2994Z" fill="#0D99FF" />
  </BaseSVGIcon>
);
