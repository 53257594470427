export enum HomePageAnchors {
  TEAM = '/#teamBlockAnchor',
  BUY = '/#buyBlockAnchor',
}

export type HomePageNavElement = {
  label: string
  anchorId?: HomePageAnchors
  isOuterLink?: boolean
  link?: string
}

export const homePageNavigation: HomePageNavElement[] = [
  {
    label: 'Team',
    anchorId: HomePageAnchors.TEAM,
  },
]
