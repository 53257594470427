import { FC } from 'react';

import { ContractProvider, ElrondApiProvider, InteractionProvider, Layout, RouteManager as Router } from 'containers';
import { ToastContainer } from 'react-toastify';

import {
  DappProvider,
} from '@multiversx/sdk-dapp/wrappers';
import {
  TransactionsToastList,
  SignTransactionsModals,
} from '@multiversx/sdk-dapp/UI';

import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/index.scss';
import { isDev } from 'config';

const App: FC = () => {
  return (
    <DappProvider
      environment={isDev ? 'testnet' : 'mainnet'}
      customNetworkConfig={{
        name: 'customConfig',
        apiTimeout: 6000,
        walletConnectV2ProjectId: 'ba3df60dd02524d1ab26dcb9009906ca',
      }}
    >
      <>
        <InteractionProvider>
          <ElrondApiProvider>
            <ContractProvider>
              <Layout>
                <ToastContainer autoClose={4000} hideProgressBar position="top-right" closeButton={false} />
                <Router />
              </Layout>
            </ContractProvider>
          </ElrondApiProvider>
        </InteractionProvider>
        <SignTransactionsModals />
        <TransactionsToastList
          successfulToastLifetime={2000}
        />
      </>
    </DappProvider>
  );
};
export default App;
