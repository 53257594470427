import { homePageNavigation } from 'containers/Header/Header.helpers'

export const menuNavLinks = [
  {
    label: 'Blog',
    isOuterLink: true,
    link: 'https://blog.artcpaclub.com/',
  },
  //   {
  //     label: 'Yield Farming',
  //     link: '/farming',
  //   },
  {
    label: 'NFT Staking',
    link: '/nft_staking',
  },
  {
    label: 'Marketplace',
    isOuterLink: true,
    link: 'https://marketplace.artcpaclub.com/',
  },
]

export const tableMenuNavLink = [
  // {
  //   label: 'Buy CPA',
  //   link: '/#buyBlockAnchor',
  // },
  ...menuNavLinks,
  ...homePageNavigation,
]
