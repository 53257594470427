import React from 'react';

import { IconProps } from '../icons.types';

import { BaseSVGIcon } from './BaseSVGIcon';

export const RedditIcon: React.FC<IconProps> = (props) => (
  <BaseSVGIcon
    width="1024"
    height="1024"
    fill="#141417"
    viewBox="0 0 1024 1024"
    {...props}
  >
    <circle cx="512" cy="512" r="512" fill="#ff4500" />
    <path
      d="M768 516c0-30.9-25.1-56-56-56-15.1 0-28.8 6-38.9 15.7-38.3-27.6-91.1-45.5-149.8-47.5l25.5-120.1 83.4 17.7c1 21.2 18.4 38.1 39.8 38.1 22.1 0 40-17.9 40-40s-17.9-40-40-40c-15.7 0-29.2 9.1-35.7 22.3l-93.1-19.8c-2.6-.6-5.3-.1-7.5 1.4-2.2 1.4-3.8 3.7-4.3 6.3l-28.5 134c-59.6 1.7-113.2 19.5-152 47.5-10.1-9.7-23.7-15.6-38.7-15.6-30.9 0-56 25.1-56 56 0 22.8 13.6 42.3 33.1 51.1-.9 5.6-1.3 11.2-1.3 16.9 0 86.2 100.3 156.1 224.1 156.1S736 670.2 736 584c0-5.7-.5-11.3-1.3-16.8 19.6-8.7 33.3-28.3 33.3-51.2zm-384.1 40c0-22.1 18-40 40-40 22.1 0 40 18 40 40 0 22.1-17.9 40-40 40s-40-17.9-40-40zM607 661.8c-27.3 27.3-79.7 29.4-95 29.4-15.4 0-67.7-2.1-95-29.4-4-4-4-10.6 0-14.7 4-4 10.6-4 14.7 0 17.2 17.2 54 23.3 80.3 23.3 26.3 0 63.1-6.1 80.4-23.3 4.1-4 10.6-4 14.7 0 4 4.1 4 10.6-.1 14.7zm-7-65.8c-22.1 0-40-18-40-40 0-22.1 18-40 40-40 22.1 0 40 18 40 40 0 22.1-18 40-40 40z"
      fill="#fff"
    />
  </BaseSVGIcon>
);
